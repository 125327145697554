import React, { Component } from 'react'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

// This component parses the JSON data from contentful and uses the rich-text-react-renderer to spit out the html values to the page


export default class ContentParser extends Component {
    render() {

        const dataToParse = this.props.contentToParse;

        console.log(dataToParse);
        return (
            <div className="m-contentful__content">
                {documentToReactComponents(dataToParse)}
            </div>
        )
    }
}
