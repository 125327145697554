import React from "react";
import { Link, graphql } from "gatsby";
import Container from "../organisms/PageContainer";
import SEO from "../atoms/seo";

import ContentParser from "../components/ContentParser";

const BlogPost = ({ data }) => {
  const { title, slug, body } = data.contentfulBlogPost;
  const bodyJSON = JSON.parse(body.body);
  return (
    <Container>
      <SEO title={title} />
      <div className="blogpost">
        <h1>{title}</h1>
        <h2>{slug}</h2>
        <ContentParser contentToParse={bodyJSON} />
        <Link to="/blogposts">View more posts</Link>
        <Link to="/">Back to Home</Link>
      </div>
    </Container>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query($slug: String) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      body {
        body
      }
    }
  }
`;